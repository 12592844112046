import { useMsal } from '@azure/msal-react';
import { Alert, Button, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { APIAuthClient } from '../Api/ApiAuthClient';
import { loginRequest } from '../MsAuth/MsAuthConfig';
import Passwordbox from '../Shared/PasswordBox';
import { useCompanyLogoStore } from '../Stores/CompanyLogoStore';
import './Login.css';
import { ApiException } from '../Api/ApiServer';
import ChooseTenantDialog from './ChooseTenantDialog';
import ChangePasswordDialog from '../Users/ChangePasswordScene';
import { useMediaPredicate } from 'react-media-hook';
import SendResetPasswordDialog from '../Users/SendResetPasswordScene';

interface LoginProps {
    onLogin?: (accessToken: string, refreshToken: string, expiresAt: Date) => void;
    loginDirect?: boolean;
    // redirectPath?: string;
}
export default function Login(props: LoginProps) {
    const params = useParams();
    useEffect(() => {
        console.log(params, 'parameters');
        if (params.redirect) {
            window.location.href = process.env.REACT_APP_GATE_URL + '/loginframe/' + encodeURIComponent(window.location.protocol + '//' + window.location.host) + '/' + encodeURIComponent(params.redirect);

        }
        else if(window.location.href.includes("szcde.cz"))
            window.location.href = "https://szcde.cz/pwmain";
        else window.location.href = "https://webtools.sudop-group.cz";

    })
    return <div>přesměrování</div>
}

