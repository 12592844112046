


import {
    BrowserAuthorizationClient,
    BrowserAuthorizationClientConfiguration,
    SettingsInStorage
} from '@itwin/browser-authorization';
export const environment: {
    production: boolean;
    authorization: BrowserAuthorizationClientConfiguration;
    settings?: SettingsInStorage;
} = {
    production: true,
    // authorization: {
    //     clientId: 'webapp-4p9sFcHmJFVA5KFUje7Y46lEq',
    //     scope: "itwin-platform offline_access",
    //     redirectUri: 'https://localhost:7186/auth/pwtoken',
    //     postSignoutRedirectUri: 'https://localhost:7186/auth/pwtoken',

    //     responseType: 'code',
    //     authority: 'https://ims.bentley.com',
        
    // },
    authorization: {
        clientId: 'webapp-dktjk4Baws1hcSGJ5Jf65Cveb',
        scope: "itwin-platform offline_access",
        redirectUri: 'https://bimvalidatorapi.azurewebsites.net/auth/pwtoken',
        postSignoutRedirectUri: 'https://bimvalidatorapi.azurewebsites.net/auth/pwtoken',
        
        // se: ,
        responseType: 'code',
        authority: 'https://ims.bentley.com',
        
    },
};
export class AuthorizationService {
    private readonly _client: BrowserAuthorizationClient;
    private readonly _config: BrowserAuthorizationClientConfiguration;
    private readonly _settings: SettingsInStorage | undefined;

    constructor() {
        this._config = environment.authorization;
        this._client = new BrowserAuthorizationClient(this._config);
        // this._settings = environment.settings ??{};
    }

    public async signIn() {
        await this._client.handleSigninCallback();
        // this._settings.
        return new Promise<boolean>((resolve, reject) => {
            this._client.onAccessTokenChanged.addOnce((token: string) =>
                resolve(token !== ''),
            );
            this._client.signIn().catch((err) => reject(err));
        });
    }

    public get client() {
        return this._client;
    }

    public get signedIn() {
        return this._client.hasSignedIn;
    }
}